import { post, deletes, get, downFile } from 'common/request'
export default {
  /**
   * 获取
   */
  // 分组管理/点单类型分页查询列表数据
  getTypeList: args => get('/residentTask/type/list', args),
  // 获取全部分组
  getTypeListAll: args => get('/residentTask/type/listAll', args),
  list: args => get('/scGridManagement/queryTreeListNoPage', args),
  // 获取志愿者活动列表数据
  volunteerActivityList: params => get('/volunteerActivity/list', params),
  // 获取志愿者管理列表数据
  volunteerInfoList: params => get('/volunteerInfo/list', params),
  //获取网格树
  getTreeList: args => get('/scGridManagement/getTreeList', args),
  // 根据id获取志愿活动详情
  getTypeById: id => get(`/residentTask/type/getById?id=${id}`),
  // 获取点单类型树形数据
  getTypeTreeList:args => get('/residentTask/type/tree', args),



  // // 根据id获取人口详情
  // getResidentArchivesById: id => get(`/residentArchives/getById?id=${id}`),
  // // 根据id获取走访信息详情
  // getInterviewInfoById: id => get(`/interviewInfo/getById?id=${id}`),
  // // 获取省的行政区划
  // getRegionInfo: args => get(`/regionInfo/list?level=1`),
  // // 获取市级的行政区划
  // getProvinceRegionInfo: provinceCode => get(`/regionInfo/list?level=2&&provinceCode=${provinceCode}`),
  // // 获取区级的行政区划
  // getCityRegionInfo: cityCode => get(`/regionInfo/list?level=3&&cityCode=${cityCode}`),
  // // 获取县级的行政区划
  // getCountyRegionInfo: countyCode => get(`/regionInfo/list?level=4&&countyCode=${countyCode}`),
  // 获取所有小区
  getGridManagement: args => get('/scGridManagement/queryAll', args),
  // // 获取居民信息表格数据
  // getPersonTableData: args => get('/residentArchives/listAll', args),
  /**
   * 插入/修改
   */
  // 新增分组管理/点单类型
  addType: args => post('/residentTask/type/add', args),
  // 导出
  exports: args => downFile('/residentArchives/exportXls', args),
  // 根据id删除
  deleteTypeById: id => deletes(`/residentTask/type/deleteById?id=${id}`),
  // 批量删除
  deleteTypeByIds: ids => deletes(`/residentTask/type/ids?ids=${ids}`),
  // 编辑分组管
  updateType: args => post('/residentTask/type/update', args),
  // 删除图片
  del: args => get(`/file/del/?id=${args}`),

  /**
     * 字典
     */
  // form里面的下拉获取数据字典
  dictData: args => get('/dict-data/getByDicKind', args),
}
