<!-- 新增分组 -->
<template>
  <div>
    <el-dialog
      :z-index="10"
      :title="title"
      :visible.sync="visible"
      width="40%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <!-- 基本信息 -->
      <ax-form ref="form" :formBuilder="formBuilder"> </ax-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onSubmit"
          :loading="saveType"
          style="background-color: #556bff"
          >保 存</el-button
        >
        <el-button @click="visible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  <script>
const formList = [
  {
    label: "分组名称", //自动获取可修改
    type: "",
    model: "typeName",
    options: {
      placeholder: "请输入",maxLength: 20,
    },
    col: { span: 24 },
    rules: [
      {
        required: true,
        message: "请输入",
      },
    ],
  },
];
import api from "./api";
export default {
  components: {},
  data() {
    return {
      api,
      visible: false,
      title: "",
      saveType: false,
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 100 },
        { col: { span: 8 } }
      ),
    };
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.visible = true;
      this.saveType = false;
      if (record.id) {
        this.id = record.id;
        this.getTypeInfo(record.id);
      }
      this.title = record.title;
    },
    // 关闭弹窗
    closeModal() {
      this.$refs.form.resetFields();
      this.visible = false;
      this.$emit("refsh");
    },
    // 提交
    async onSubmit() {
      this.$refs.form.form.validateFields(async (err, value) => {
        if (err) return;
        this.saveType = true;
        console.log(value, "336");
        try {
          if (this.id) {
            value.id = this.id;
            const res = await api.updateType(value);
            console.log(res);
            if (res.status === 200) {
              this.$message.success("编辑成功");
              this.saveType = false;
              // 提交成功后关闭弹窗
              this.closeModal();
            } else {
              this.$message.error("编辑失败");
              this.saveType = false;
            }
          } else {
            const res = await api.addType(value);
            console.log(res);
            if (res.status === 200) {
              this.$message.success("新增成功");
              this.saveType = false;
              // 提交成功后关闭弹窗
              this.closeModal();
            } else {
              this.$message.error("新增失败");
              this.saveType = true; // 验证通过后再设置
            }
          }
        } catch (err) {
          console.log(err);
          this.$message.error("新增失败");
          this.saveType = false;
        }
      });
    },
    // 根据id获取分组详情
    async getTypeInfo(id) {
      const res = await api.getTypeById(id);
      console.log(res, "分组详情");
      this.$refs.form.setFieldsValue(res.data);
    },
  },
};
</script>
  
  <style lang="less" scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.el-input__inner {
  height: 32px !important;
  line-height: 32px !important;
}
/deep/.el-range-editor.el-input__inner {
  width: 100%;
  padding: 0 15px;
}
.dialog-footer {
  display: flex;
  justify-content: end;
}

.search-title {
  // font-size: 0.072rem;
  font-weight: 800;
}
</style>